import type { ARTICLE_NATURE, ARTICLE_STATUS, ARTICLE_TYPE, BANNER_DATA_TYPE, JsonString, MediaType, PaginationParams, TOPIC_COMPONENT_TYPE, TOP_TYPE, TimeString, USER_TYPE } from '@qcwp/common'
/**
 * 查询文章列表请求参数
 */
export interface ArticleListParams extends PaginationParams {
  articleNature?: ARTICLE_NATURE
  articleStatus?: ARTICLE_STATUS
  articleType?: ARTICLE_TYPE
  articleRecomLocation?: ArticleRecomLocationEnum
  hot?: boolean | number // 是否热门
  mediaName?: string
  mediaType?: USER_TYPE
  mediaUserId?: string
  summary?: string // 文章要摘
  title?: string
  top?: boolean
  topStartTime?: TimeString// 置顶开始时间time YYYY-MM-DD
  topEndTime?: TimeString // 置顶结束时间 time YYYY-MM-DD

  carSeriesId?: string
  columnId?: string
  ids?: string
}
export interface ArticleSearchListParams extends PaginationParams {
  // authorName?: string
  // sysUserName?: string
  title?: string
  // mediaName?: string
  articleType?: ARTICLE_TYPE
}
/**
 * 查询文章列表响应数据
 */
export interface ArticlePagesResponse {
  articleNature: ARTICLE_NATURE
  articleStatus: ARTICLE_STATUS
  articleType: ARTICLE_TYPE
  id: string
  linkUrl?: string
  hot: boolean // 是否热门
  top: boolean
  title: string
  summary: string // 文章简介
  imgs: string[]
  showCreateTime: TimeString // time
  look: number
  mediaAvatar: string
  mediaName: string
  mediaType: MediaType
  mediaUserId: string
  payTime?: number
  mediaGrade?: string
}

// 1首页 2媒体 3资讯 4专题 5行情 6进销商
export enum ArticleRecomLocationEnum {
  HOME = '1',
  NEW_HOME = '2',
  HANGQING = '3',
  MEDIA_HOME = '4',
  TOPIC = '5',
  DEALER = '6',
}

/**
 * 查询文章列表请求参数
 */
export interface ArticleRecommendParams extends PaginationParams {
  articleRecomLocation?: ArticleRecomLocationEnum
  columnId?: string
  mediaUserId?: string
  hot?: 0 | 1
  title?: string
}
export interface ArticleRecommendResponse {
  articleType: ARTICLE_TYPE
  id: string
  imgs: string[]
  linkUrl: string
  showCreateTime: TimeString
  title: string
  summary: string
}

/**
 * 查询文章排行榜列表
 */
export interface ArticleTopParams extends PaginationParams {
  topType: TOP_TYPE
}
export type ArticleTopResponse = Record<TOP_TYPE, ArticleRecommendResponse[]>

/**
 * 文章详情
 */
export interface ArticleDetailInfoVO {
  articleContent: string
  metaDescription: string
  metaKeyword: string
}
export interface ArticleCarRefVO {
  id: string
  createTime: TimeString
  carBrandId: string
  carBrandName: string
  carSeriesId: string
  carSeriesName: string
  carVendorId: string
  carVendorName: string
}
export interface ArticleWorkerVO {
  articleId: string // 资讯id integer
  createTime: TimeString // 创建时间 string
  id: string // 编号 integer
  remark: string // 备注 string
  userId: string // 人员id integer
  userName: string // 人名称 string
  workerType: '1' | '2' // 1=拍摄人员,2=主持人员
}
export interface ArticleDetailResponse {
  aiHelperType: '0' | '1' // 0=不是,1=是
  articleDetailInfoVO: ArticleDetailInfoVO
  articleNature: ARTICLE_NATURE
  articleStatus: ARTICLE_STATUS
  articleTags: string[]
  articleType: ARTICLE_TYPE
  authorName: string
  bindTopic: boolean // 是否绑定专题
  // carName: string | null
  // @deprecated
  // articleCarRefVOS: ArticleCarRefVO[]
  collect: boolean
  commentCount: number
  focus: boolean
  hot: boolean
  id: string
  imgs: string[]
  linkUrl: string
  look: number
  mediaAvatar: string
  mediaGrade?: string
  mediaName: string
  mediaType: USER_TYPE
  mediaUserId: string
  mediaIntro?: string
  payTime: number
  showCreateTime: TimeString
  summary: string
  sysUserId: string
  sysUserName: string
  title: string
  top: boolean
  articleWorkerVOList: ArticleWorkerVO[]
  porArticleCarRefList: ArticleCarRefVO[]
  updateTime: string
}

export interface ArticleColumnParams {
  columnName?: string // 栏目全称
  columnType?: 1 | 2 // 1=文章,2=专题
  orderNum?: string // 显示顺序
  parentId?: string
  path?: string
  shortName?: string
  status?: 0 | 1 // 0=正常,1=停用
  visible?: 0 | 1 // 0=显示,1=隐藏
  level?: number
}
export interface ArticleColumnResponse {
  columnName: string
  id: string
  orderNum: string
  parentId: string
  path: string
  shortName: string
  status: 0 | 1
  visible: 0 | 1
}

// 1=首页主页,2=网评号主页,3=资讯主页,4=专题大全主页,5=行情导购主页,6=经销商主页
// export type BannerShowLocation = 'HOME_BANNER' | 'MEDIA_BANNER' | 'NEWS_BANNER' | 'TOPIC_BANNER' | 'MARKET_BANNER' | 'DEALER_BANNER'
export enum BannerShowLocation {
  HOME = '1',
  MEDIA_HOME = '2',
  NEW_HOME = '3',
  TOPIC = '4',
  HANGQING = '5',
  DEALER = '6',
  VIDEO = '7',
}
/**
 * 查询焦点图列表
 */
export interface ArticleBannerParams extends PaginationParams {
  bannerShowLocation?: BannerShowLocation
  carDealerId?: string
}
export interface ArticleBannerResponse {
  bannerShowLocation: BannerShowLocation[]
  beginTime: TimeString
  cover: string
  dataId: string
  dataType: BANNER_DATA_TYPE
  endTime: TimeString
  hide: 0 | 1
  id: string
  articleType?: ARTICLE_TYPE
  linkUrl: string
  title: string
  watermark?: string
}

/**
 * 查询文章评论列表
 */
export interface ArticleCommentParams extends PaginationParams {
  articleId?: string
  parentId?: string
  level?: number
}

export interface ArticleCommentResponse {
  id: string
  articleId: string
  replyCount: string
  provinceName: string | null
  cityName: string | null
  content: string
  level: string
  mediaAvatar: string | null
  mediaName: string | null
  mediaUserId: string
  createTime: TimeString
  remark: string | null // 备注
  toMediaAvatar: string | null // 被评论媒体头像
  toMediaName: string | null // 被评论媒体名
  toMediaUserId: string | null // 被评论媒体ID
  toCommentId: string | null // 被评论ID
}
// 新增文章评论
export interface AddArticleCommentParams {
  articleId: string
  content: string
  toCommentId?: string
}
export interface AddArticleCommentResponse {
  id: string
  createTime: TimeString
  articleId: string
  content: string
  level: string
  provinceName: null | string
  cityName: null | string
  mediaUserId: string
  mediaName: null | string
  mediaAvatar: null | string
  toMediaUserId: null | string
  toMediaName: null | string
  toMediaAvatar: null | string
  childCommentCount: null | string
}

export type TagsSearchType = 1 | 2 | 3 // 1=站内关键字搜索,2=站内路由跳转,3=外链接跳转
export interface HotTagsResponse {
  label: string
  linkUrl: string
  tagsSearchType: TagsSearchType
}

export interface ArticleClass {
  label: string
  id: string
  url: string
  isShow?: boolean
  [key: string]: any
}

// 查询专题列表
export interface TopicPagesParams extends PaginationParams {
  columnId?: string
  title?: string
  metaKeywords?: string
  metaDescription?: string
  bodyJson?: string
}

export interface TopicPagesResponse {
  bodyJson: JsonString
  columnId: string
  cover: string
  id: string
  metaDescription: string
  metaKeywords: string
  title: string
}

export interface TopicDetailResponse {
  bodyJson: JsonString
  columnId: number
  cover: string
  createTime: TimeString
  id: string
  metaDescription: string
  metaKeywords: string
  remark: string
  title: string
}

export enum TopicComponentDataType {
  'ARTICLE' = '1',
  'TOPIC' = '2',
  'BANNER' = '3',
}
export interface TopicDetailComponentDataParams {
  topicComponentType: TOPIC_COMPONENT_TYPE
  topicComponentDataType: TopicComponentDataType
  topicComponentKey: number
}
export type TopicDetailComponentDataResponse = TopicDetailComponentDataArticleItemVO[] | TopicDetailComponentDataBannerItemVO[] | TopicDetailComponentDataTopicItemVO[]

export interface TopicDetailComponentDataArticleItemVO extends ArticlePagesResponse { }
export interface TopicDetailComponentDataBannerItemVO extends ArticleBannerResponse { }
export interface TopicDetailComponentDataTopicItemVO {
  cover: string
  createTime: TimeString
  id: string
  remark: string
  title: string
}

/**
 * 获取资讯绑定的专题请求参数
 */
export interface BindTopicsParams {
  dataId: string
  dataType: ARTICLE_TYPE
}
/**
 * 获取资讯绑定的专题响应数据
 */
export interface BindTopicsResponse {
  cover: string
  createTime: TimeString
  id: string
  remark: string | null
  title: string
}

export enum LIVE_STATUS {
  'ERROR' = '-1',
  'NORMAL' = '0',
  'END' = '1',
}

export interface LiveMedia {
  liveId: string
  userId: string
  mediaUserId: string
  mediaAvatar: string
  mediaName: string
  mediaType: USER_TYPE
  mediaGrade: string
  focus: boolean
}
export interface LiveDetailResponse {
  id: string
  liveTopic: string
  liveIntro: string
  liveCover: string
  isLook: boolean
  isLike: boolean
  liveLikeTotal: string
  liveLookTotal: string
  liveStartTime: string
  liveEndTime: string
  liveUrl: string
  liveRecom: '0' | '1' // 0=不推,1=推荐
  remark: string
  liveStatus?: LIVE_STATUS
  carBrandId: string | null
  carBrandName: string | null
  carBrandLogo: string | null
  carSeriesId: string | null
  carSeriesName: string | null
  carVendorId: string | null
  carVendorName: string | null
  /**
   * @deprecated
   */
  mediaUserVO: LiveMedia
  mediaUserVOList: LiveMedia[]
  danmuStatus: '0' | '1'
}

export interface LiveBarrageListParams {
  currentPageMaxDanmuTime?: string // 时间戳
  currentPageMinDanmuTime?: string // 时间戳
  liveId: string
}
export interface LiveBarrageParams {
  cityId?: string
  cityName?: string
  content?: string
  mediaName?: string
  id?: string
  ip?: string
  liveId: string
  provinceId?: string
  provinceName?: string
  remark?: string
  userId?: string
  videoFrame?: string
  visible?: '0' | '1' // 0=显示,1=隐藏
  isAnon?: boolean // 匿名|不匿名
}

export interface LiveBarrageListResponse {
  id: string
  createTime: TimeString
  liveId: string
  content: string
  userId: string
  userName: string | null
}
export interface LiveInfoResponse {
  liveLikeTotal: string
  liveLookTotal: string
}

export enum LIVE_TIME_STATUS {
  'WAIT' = '0',
  'END' = '1',
  'LIVING' = '2',
  'WAIT_OR_LIVING' = '3',
}
export interface LiveListParams extends PaginationParams {
  carBrandId?: string
  carBrandName?: string
  carSeriesId?: string
  carSeriesName?: string
  carVendorId?: string
  liveStatus?: LIVE_TIME_STATUS // 0=待直播,1=已结束,2=直播中
  id?: string
  liveCover?: string
  liveRecom?: '0' | '1' // 0=不推,1=推荐
  liveStartTime?: string
  liveTopic?: string
  liveUrl?: string
  userId?: string
}
export type LiveListResponse = Omit<LiveDetailResponse, 'liveIntro'>

export interface DailyEssenceParams extends PaginationParams {
  numDate?: TimeString
  number?: string // 期数
  id?: string
  numWeek?: string
  title?: string
}

export interface mediaUserVO {
  mediaAvatar: string // 媒体名称 string
  mediaName: string // 媒体名称 string
  mediaUserId: string //
}

export type DailyDataRefType = '1' | '4' // 1=文章,4=直播
export type DailyEssenceColumn = '1' | '2' | '3' // 1=今日精华,2=今天导读,3=测评试驾 string
export interface DailyEssenceArticleDataRefVO {
  createTime?: TimeString // 创建时间 string
  dataRefId: string // 数据id integer
  dataRefImgs: string[] // 关联图片 string
  dataRefTitle: string // 关联标题 string
  dataRefType: DailyDataRefType
  essenceColumn: DailyEssenceColumn
  essenceId?: string // 精华id integer
  id?: string // 编号 integer
  mediaUserVOList: mediaUserVO[] // 媒体用户列表
  remark?: string // 备注 string
  sort?: string // 排序 integer
  look: number
  articleType: ARTICLE_TYPE
}

export interface DailyEssenceResponse {
  cover: string | null
  createTime: TimeString
  id: string
  numDate: TimeString
  number: string
  numWeek: string
  title: string
  calendar: {
    // ...some other
    lunarmonth: string
    lunarday: string
    huangli: {
      yi: string[]
      // ...some other
    }
  }
  essenceDataRefVOList: DailyEssenceArticleDataRefVO[]
}

export interface DailyItemData {
  dataRefId: string
  dataRefImgs: string[]
  dataRefTitle: string
  dataRefType: DailyDataRefType
  essenceColumn: DailyEssenceColumn
  essenceId: string
  id?: string
  sort?: string
  userId?: string
}

export interface DailyCreateParams {
  essenceDataRefDTOS: DailyItemData[]
  remark?: string
}
